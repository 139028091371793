import React from 'react'

export default function Registration() {

  return (
    <div className='container-fluid p-3 text-center'>

      <h1 className='wor_fg'>EIN/Sale Tax Registration</h1>
      <br />
      <p>Welcome to War on Retail we are glad you have decided to shop with us! Please fill out the registration below. We are no longer open to the public. An EIN or Business License is required to register to shop with us. You will be asked for additional verification to complete your registration please upload a scan of your EIN or Business License and read carefully the declaration and rules. Hard copies of our rules are listed below. We hope to see you soon! </p>
    </div>
  )
}
