import React from 'react'

export default function AuctionInformation() {

  
  // let hdr3 ="HUGE WHOLESALE PALLET AUCTION!!! RIGHT OFF THE FlOOR ALMOST 250 PALLETS SOLD TO THE HIGHEST BIDDER!"
  let hdr3 = "Huge Wholesale Pallet Auction!!! Right off the floor almost 250 pallets sold to the hightest bidder!"


  return (
    <div className='container-fluid p-3 '>

      <h1 className='wor_fg text-center'>Events & Sales</h1>
      <br />
      <h3 className='wor_fg text-center'>Auctions</h3>
      <br />
      <h3 className='text-center'>{hdr3}</h3>

      <hr className='wor' />

      <p>WHEN - Listed by Tuesday night & open till Sunday night at 9pm</p>


      <p>WHERE -  https://waronretail.hibid.com/                   215 Robinson Dr. Fayetteville, GA 30214.         War On Retail</p>


      <p>WHAT - Around 250 new lots of merchandise are all sold every week!</p>


      <p>WHY - BECAUSE WE NEED SPACE! We are receiving 50+ truckloads per week and we want to get YOU the best merchandise at the best price! So come get a good deal and help us make some room!</p>



      <p>We are going to be selling almost 250 pallets to the highest bidder regardless of price. All merchandise is sold as-is, untested and there are no refunds or warranties. </p>

      <p>10% Buyers Premium will apply to all auction purchases </p>
      <p>Sales Tax (7%) will be added if you do not bring or have your EIN or Sales Tax Number on file</p>
      <p>Card fee is 3%</p>
      <p>All merchandise must be paid  on the same day of pick up (if paid in cash) and sent within the beginning of the week if paid via Credit Card, Cashapp, Venmo or Zelle. </p>
      <p>ALL CARDS WILL BE PROCESSED FRIDAY IF NO RESPONSE IS GIVEN ABOUT PICK UP!</p>
      <p>You have 5 business days to pick up your won lots. NO EXCUSES, EXCEPTIONS OR REFUSALS. </p>




      <p>Doors will open at 10 am for PICK UP</p>

      <p>Please contact us at 470-473-0018 with any questions!</p>



      <p>Stay updated and linked to what we have going on with our upcoming events on Facebook!</p>

    </div>
  )
}
