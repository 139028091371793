import React from 'react'

export default function Home() {
  return (
    <div className='container-fluid mt-3 p3'>

      <h4 className='wor_fg text-center'>Welcome to</h4>
      <h1 className='wor_fg text-center'>WAR ON RETAIL</h1>
    
      <br />

      <h4 className='text-center'>We sell pallets and truckloads of overstock, store return, and liquidation items!</h4>

        <br />

        <p>Welcome to War on Retail!</p>

      <p>Here we have moved from selling pallets individually in-house to now auctions every month. This plan is to continue to sell truckloads to our customers and give the individual pallet buyers an exciting way to come purchase. Auction dates will be posted on our Facebook page so please stay updated with us! </p>

      <p>We have over 500 pallets of merchandise available online every week. We have pallets from many different retailers and typically carry electronics, tools, general merchandise, appliances, and more. Our products are a mixture of overstock, store returns, and liquidation, not all of it is brand new and there are no guarantees. Currently, we are closed to the public Monday through Friday for only PICK UPS . Feel free to reach out to our sales representative Stephanie or give us a shout on Facebook.</p>



      <p>We appreciate all of our loyal customers!</p>

      <p>If you have any questions, visit our website: waronretail.com, our Facebook group, and our page: War on Retail.  215 Robinson Dr. Fayetteville, GA 30214</p>


    </div>
  )
}


// <img className='p-3' src="/images/welcome.png" width={"100%"} height={"auto"} />