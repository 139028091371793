import React from 'react'

export default function MainWarehouse() {
  return (
    <div className='container-fluid p-3 text-center'>

      <div className='container-fluid p-3'>
        <img className='' src="/images/main-warehouse.webp" alt="main-warehouse" width="100%" height="auto" />

      </div>

      <div className='container-fluid p-3'>


      <h1 className='wor_fg'>Welcome To The Main Bulding!</h1>

        <p>We sell pallets and truckloads of overstock, store return, and liquidation items through an online a
          Come see all our new product listed on HiBid every week!</p>
      </div>

    

    </div>
  )
}
