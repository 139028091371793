import $$ from './client/store'
import { Routes, Route, Outlet } from "react-router-dom"

import Home from './features/home/Home'
import MainWarehouse from './features/main-warehouse/MainWarehouse';
import AuctionInformation from './features/auction-information/AuctionInformation';
import Registration from './features/registration/Registration';
import Policies from './features/policies/Policies';

import ContactUs from './features/contact-us/ContactUs'
import AboutUs from './features/about-us/AboutUs'
import NoPage from './core/NoPage'


$$.setup(); // init store 

function App() {

  return (
    <>
      <Routes>

        <Route index  path="/home" element={<Home />} />
        <Route exact path="/main-warehouse" element={<MainWarehouse />} />
        <Route exact path="/auction-information" element={<AuctionInformation />} />
        <Route exact path="/registration" element={<Registration />} />
        <Route exact path="/policies" element={<Policies />} />
        <Route exact path="/about-us" element={<AboutUs />} />
        <Route exact path="/contact-us" element={<ContactUs />} />

        <Route exact path="/" element={<Home />} />
        <Route path="*" element={<NoPage />} />

      </Routes>

      <Outlet />

    </>
  );

}

export default App;