// HOME
// MAIN WAREHOUSE
// AUCTION INFORMATION
// REGISTRATION
// POLICIES

const app = {};

app.setup = () => {
    console.log("In store setup")   
 
    console.log("Leaving store setup ...");
};


export default app;