import React from 'react'

export default function Policies() {

  return (
    <div className='container-fluid p-3 text-center'>

{/* <h1 className='wor_fg'>WAR ON RETAIL POLICY AGREEMENT</h1> */}
      <h1 className='wor_fg'>War On Retail Policy Agreement</h1>

      <br />

      <div className='row'>
        <div className='col-12 col-md-6'>
          <p>For safety purposes, we do not allow anyone under the age of 18 to enter the warehouse floor. <b>Children under the age of 18 will not be allowed in the building for any reason.</b></p>
          <p>We have all our new customers sign our Declaration of Understanding stating that we do not test, manifest, or open any products. We buy as is and sell as is therefore we are not responsible for anything missing, broken, or damaged in the pallets.</p>
          <p>We do ask for pick-ups to come earlier than 4pm. After 4:30 pm your order pick up date will be moved to the following business day. We accept customers up to 5 pm, but will no longer will add pick ups after 4:30 pm to the days load out. The earlier the better to ensure we finish the day on time. Our job is to get your purchases to you to load. We do not hand load or hand stack your product for you. If you need assistance in loading out, please let the office person know when you pay. To keep load outs moving in a timely matter, we may ask you to come back later in the day when it is slower or the following business day if you have a large order. In the Main building we give everyone 5 business days to pick up their orders. </p>
          <p>Before you shop, we do now require you too fill out our policy agreements through our Docu-sign QR Code.  This will take less than 5 minutes to fill out and will speed up the process of checking out. Thanks!</p>
        </div>

        <div className='col-12 col-md-6'>
          <p>New Customers</p>
          <p>War on Retail LLC is in no way responsible for the product on or in each pallet. <b>All merchandise is sold AS IS, WHERE IS, HOW IS, with no guarantees, refunds, returns, warranties, or exchanges.</b> By purchasing a pallet you understand that the merchandise is NOT all brand new. It is a mixture of overstock, store returns, unclaimed freight, and liquidation. In no way does War On Retail guarantee this to be new or guarantee you make a profit. You are responsible for the pallet you choose.If, for any reason, you cannot take your pallet with you, you may leave it at our warehouse for up to two business days from the time of purchase. After no response or pick up of won lots, War On Retail will reclaim and relist or dispose of your merchandise.</p>
        </div>

      </div>

    </div>
  );
}
