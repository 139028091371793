import { Link } from "react-router-dom";
// import $$ from '../client/store';


export default function Navbar() {

const brandImage = "/images/brand.jpg";


  return (
    <div className="container-fluid m-0 px-0 pt-0 pb-0 g-0" >
      <nav className="navbar navbar-expand-md bg-white p-0 m-0 g-0" aria-label="Offcanvas navbar large">

        <div className="container-fluid">

          <Link className="navbar-brand" to="/home">
            <img src={brandImage} alt="waronretail" width='220px' height='auto' />
          </Link>

          <button className="navbar-toggler navtog" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar">
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="offcanvas offcanvas-top h-75 " tabIndex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
            <div className="offcanvas-header">
              <h5 className="offcanvas-title" id="offcanvasNavbarLabel"> <img src={brandImage} alt="wholesalez" width="180" height="36" /></h5>
              <button id="btnOff" type="button" className="btn-close btn-close-dark" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>

            <div className="offcanvas-body">
              <ul className="navbar-nav  ms-auto mb-2 mb-lg-0 ">

                <li className="nav-item">
                  <Link className="nav-link bsfont-bold" exact="true" to="/home" onClick={() =>(document.getElementById('btnOff').click())} >
                  Home
                  </Link>
                </li>

                <li className="nav-item">
                  <Link className="nav-link bsfont-bold"  to="/main-warehouse" onClick={() =>(document.getElementById('btnOff').click())}  >
                  Main Warehouse
                  </Link>
                </li>

                <li className="nav-item">
                  <Link className="nav-link bsfont-bold"  to="/auction-information" onClick={() =>(document.getElementById('btnOff').click())}  >
                  Auction Information
                  </Link>
                </li>

                <li className="nav-item">
                  <Link className="nav-link bsfont-bold"  to="/registration" onClick={() =>(document.getElementById('btnOff').click())}  >
                  Registration
                    </Link>
                </li>

                <li className="nav-item">
                  <Link className="nav-link bsfont-bold"  to="/policies" onClick={() =>(document.getElementById('btnOff').click())}  >
                   Policies
                    </Link>
                </li>


              </ul>

            </div>

          </div>

        </div>
      </nav>
      <div className='p-0 m-0 g-0 ' style={{width:'100%', height:'.3rem', backgroundColor: 'rgb(79, 117, 63)'}}></div>  
      </div>
  );
}
