// index.js
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import Navbar from './core/Navbar';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
     <BrowserRouter>
         <div className="container-fluid sticky-top mb-0 p-0  g-0" >
            <Navbar />      
         </div>
         <App />
      </BrowserRouter>
);


